<template>
  <ValidationObserver
    tag="div"
    class="question column"
    :class="width"
    v-slot="{ errors }"
    v-if="!reviewMode"
  >
    <div class="question-text has-text-weight-bold">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="getFirstError(errors)">
        {{ getFirstError(errors) }}
      </p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content">
      <template v-if="categories">
        <div :key="index" v-for="(category, index) in categories" class="category-content">
          <p v-if="category.display_title && category.sub_questions.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
          <ValidationProvider
            v-for="subQuestion in category.sub_questions"
            :key="subQuestion.id"
            :rules="{...validationRules, ...(qOpts.total ? {total: { total: qOpts.total, totalValueCurrent: totalValue }}: {})}"
            v-slot="{ errors, valid }"
            tag="div"
            class="my-3"
          >
            <div class="field is-horizontal">
              <div class="field-label is-normal" :class="[isRtl ? 'text-right' : 'text-left']">
                <label
                  class="label"
                  :class="{ 'has-text-danger has-text-weight-bold': errors[0] }"
                >
                  <div v-html="subQuestion.question[lang]"></div>
                </label>
              </div>
              <div class="field-body">
                <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                  <b-input
                    expanded
                    :placeholder="$t(`multiple_input.${qOpts.type}_placeholder`)"
                    :type="qOpts.type"
                    v-numeric-only="qOpts.type === 'number'"
                    :value="value[questionKey(question.qid, subQuestion.code)]"
                    @input="
                      onChange(
                        questionKey(question.qid, subQuestion.code),
                        $event
                      )
                    "
                    :disabled="loading"
                    :use-html5-validation="false"
                    :step="`any`"
                  ></b-input>
                  <p class="control" v-if="qOpts.suffix">
                    <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
                    <span v-else class="button is-static">{{ qOpts.suffix }}</span>
                  </p>
                </b-field>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </template>
      <template v-else>
        <ValidationProvider
          v-for="subQuestion in question.sub_questions"
          :key="subQuestion.id"
          :rules="{...validationRules, ...(qOpts.total ? {total: { total: qOpts.total, totalValueCurrent: totalValue }}: {})}"
          v-slot="{ errors, valid }"
          tag="div"
          class="my-3"
        >
          <div class="field is-horizontal">
            <div class="field-label is-normal" :class="[isRtl ? 'text-right' : 'text-left']">
              <label
                class="label"
                :class="{ 'has-text-danger has-text-weight-bold': errors[0] }"
              >
                <div v-html="subQuestion.question[lang]"></div>
              </label>
            </div>
            <div class="field-body">
              <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input
                  expanded
                  :placeholder="$t(`multiple_input.${qOpts.type}_placeholder`)"
                  :type="qOpts.type"
                  v-numeric-only="qOpts.type === 'number'"
                  :value="value[questionKey(question.qid, subQuestion.code)]"
                  @input="
                    onChange(
                      questionKey(question.qid, subQuestion.code),
                      $event
                    )
                  "
                  :disabled="loading"
                  :use-html5-validation="false"
                  :step="`any`"
                ></b-input>
                <p class="control" v-if="qOpts.suffix">
                  <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
                  <span v-else class="button is-static">{{ qOpts.suffix }}</span>
                </p>
              </b-field>
            </div>
          </div>
        </ValidationProvider>
      </template>
      <template v-if="qOpts.total && qOpts.type == 'number'" >
        <div>
          <div class="my-5">
            <div class="field is-horizontal">
                <div class="remaining-total">
                  <div class="remaining-left">
                    {{$t("multiple_input.remaining")}}
                  </div>
                  <div class="remaining-right">
                    {{qOpts.total - totalValue }}
                  </div>
                  <div class="remaining-left">
                    {{$t("multiple_input.total")}}
                  </div>
                  <div class="remaining-right">
                    {{totalValue}}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    </div>
  </ValidationObserver>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div v-if="categories" class="question-content">
      <div :key="index" v-for="(category, index) in categories" class="category-content">
        <p v-if="category.display_title && category.sub_questions.length > 0" class="category-title">
          {{ category.title[lang] || Object.values(category.title)[0] }}
        </p>
        <div
          v-for="subQuestion in category.sub_questions"
          :key="subQuestion.id"
          :rules="{...validationRules, ...(qOpts.total ? {total: { total: qOpts.total, totalValueCurrent: totalValue }}: {})}"
        >
          <div class="field is-horizontal is-align-items-center mb-2">
            <div class="column is-6-desktop p-0" :class="[isRtl ? 'text-right' : 'text-left']">
              <div v-html="subQuestion.question[lang]"></div>
            </div>
            <div class="column is-6-desktop p-0">
              <b-field>
                <b-input
                  expanded
                  :placeholder="$t('multiple_input.no_data_input')"
                  :type="qOpts.type"
                  v-numeric-only="qOpts.type === 'number'"
                  :value="value[questionKey(question.qid, subQuestion.code)]"
                  :readonly="loading"
                  :use-html5-validation="false"
                  :step="`any`"
                ></b-input>
                <p class="control" v-if="qOpts.suffix">
                  <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
                  <span v-else class="button is-static">{{ qOpts.suffix }}</span>
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      v-for="subQuestion in question.sub_questions"
      :key="subQuestion.id"
      :rules="{...validationRules, ...(qOpts.total ? {total: { total: qOpts.total, totalValueCurrent: totalValue }}: {})}"
    >
      <div class="field is-horizontal is-align-items-center mb-2">
        <div class="column is-6-desktop p-0" :class="[isRtl ? 'text-right' : 'text-left']">
          <div v-html="subQuestion.question[lang]"></div>
        </div>
        <div class="column is-6-desktop p-0">
          <b-field>
            <b-input
              expanded
              :placeholder="$t('multiple_input.no_data_input')"
              :type="qOpts.type"
              v-numeric-only="qOpts.type === 'number'"
              :value="value[questionKey(question.qid, subQuestion.code)]"
              :readonly="loading"
              :use-html5-validation="false"
              :step="`any`"
            ></b-input>
            <p class="control" v-if="qOpts.suffix">
              <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
              <span v-else class="button is-static">{{ qOpts.suffix }}</span>
            </p>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { BlockRandomizationMixin } from "@/mixins/block-randomization";
import { NumericOnlyDirective } from "@/directives/numeric-only.directive";
import { isRtl } from '@/utils/survey';
export default {
  name: "MultipleInput",
  mixins: [QuestionMixin, BlockRandomizationMixin],
  directives: {
    "numeric-only": NumericOnlyDirective,
  },
  methods: {
    onChange(key, value) {
      this.$emit("input", { [key]: `${value}` });
    },
    getFirstError(errors) {
      const firstExistsErrors = Object.values(errors).find((item) => item[0]);
      if (firstExistsErrors) return firstExistsErrors[0];
    },
  },
  computed: {
    totalValue () {
      if (this.qOpts.type !== "number") return 0;
      return this.question.sub_questions.reduce((total, subQuestion) => {
          const currentValue = this.value?.[this.questionKey(this.question.qid, subQuestion.code)]
          if (typeof currentValue === 'number' || typeof currentValue === 'string') {
            total += +currentValue
          }
          return total
      }, 0)
    },
    isRtl() {
      return isRtl();
    }
  }
};
</script>
<style lang="scss">
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.field-label {
  min-width: 208px;
  label {
    div {
      overflow-wrap: break-word;
    }
  }
}
.remaining-total {
  font-weight: 700;
  width: 100%
}
.remaining-left {
  float: left;
  width: 40%;
  text-align: left;
}
.remaining-right {
  float:left;
  width: 60%;
  text-align: center;
}
</style>
